<template>
    <div class="mt-2 mb-2">
        <label>
            <input name="checkbox-component" v-model="model" type="checkbox" :disabled="readonly" class="me-2" />
            {{ label }}
        </label>
    </div>
</template>

<script setup>
import { defineProps, defineModel } from 'vue';

const props = defineProps({
    label: {
        required: true,
        type: String
    },
    readonly: {
        default: false,
        type: Boolean
    }
});

const model = defineModel({
    prop: 'modelValue',
    event: 'update'
});

</script>