<template>
  <a class="content-teaser position-relative" :class="contentClass" :href="props.content.url">
    <div class="content-teaser__image-container">
      <img v-if="props.content.image" class="content-teaser__image" :src="props.content.image" srcset=""
        :alt="'Vorschaubild ' + props.content.name" />
    </div>
    <div class="content-teaser__body">
      <div class="content-teaser__type">{{ props.content.type_verbose }}</div>
      <div v-if="props.content.favored" class="content-teaser__favor">
        <span class="fa fa-heart favor-icon favor-icon--active">
          <span class="sr-only">Auf dem Merkzettel</span>
        </span>
      </div>
      <div class="clearfix" />
      <div class="content-teaser__title" style="max-width: 275px; word-break: break-word;">{{ props.content.name }}</div>
      <div class="content-teaser__abstract">{{ props.content.teaser }}</div>
      <div class="content-teaser__footer">
        <div class="content-teaser__competences">
          <span v-for="(competence, index) in props.content.competences" :key="index" v-tooltip="competence.name"
            class="content-teaser__competence" :class="competence.icon" />
        </div>
      </div>
    </div>
    <div v-if="isAIContent" class="content-is-ai p-2 position-absolute end-0">
      <img :src="getAIBadgeImage" style="width: 50px;" />
    </div>
  </a>
</template>

<script setup>
import { computed } from 'vue';

//  --------------------------------------------------------------------------------------------------------------------
//  models + props
//  --------------------------------------------------------------------------------------------------------------------
const props = defineProps({
  content: {
    default() {
      return {};
    },
    type: Object
  }
});

//  --------------------------------------------------------------------------------------------------------------------
//  computed
//  --------------------------------------------------------------------------------------------------------------------
const contentClass = computed(() => {
  return props.content.type ? `content-teaser--${props.content.type}` : '';
});

const isAIContent = computed(() => props.content.is_ai_content);

const getAIBadgeImage = computed(() => "https://dll-production.s3-de-central.profitbricks.com/static/img/content/ai_badge.svg");
</script>

<style scoped></style>
